import styled from 'styled-components';

export const Body = styled.div`
  padding: 75px;
  text-align: center;
  width: 820px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 35px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ParaText = styled.p`
  margin: 0 0 20px 0;
  padding: 0;
`;

export const Header = styled.h1`
  padding: 0;
  margin: 0 0 30px 0;
  font-family: 'MrAlex', arial, sans-serif;
  font-size: 60px;
  line-height: 60px;
  color: #00539f;
`;

export const ImageStyle = styled.img`
  display: block;
  width: 100%;
  max-width: 520px;
  height: auto;
  margin: 0 auto 50px auto;
`;

export const LinkStyle = styled.a`
  width: 70%;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 130%;
  color: #9a9a9a;
`;

export const Header2 = styled.h2`
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'MrAlex', sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #00539d;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
